<template>
  <b-card @mouseover="update">
    <b-card-header>
      <b-card-title
        >Menciones de marca -
        {{ audienceData.brands_mentions_count }}
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <hooper ref="brandCarousel" :settings="configSlide">
        <slide v-for="(item, index) in audienceData.brands_mentions" :key="index" :index="index">
          <b-card
          style="width:90%"
            no-body
            border-variant="light"
          >
            <b-card-header class="p-0" >
              <div></div>
              <b-dropdown
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="flat-dark"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="cursor-pointer"
                  />
                </template>
                <b-dropdown-item  :href="makeLink(true, item.username)" target="_blank">
                  Perfil BrandMe
                </b-dropdown-item>
                <b-dropdown-item :href="makeLink(false,item.username)" target="_blank">
                  Perfil Instagram
                </b-dropdown-item>
              </b-dropdown>
            </b-card-header>
            <b-card-body>
              <div class="profile-image-wrapper">
                <div class="profile-image p-0">
                  <div class="profile-img">
                    <b-img :src="item.avatar" :rounded="true" fluid class="border-light" width="80px" alt="profile photo" />
                  </div>
                </div>
              </div>
              <h6>{{ item.username }}</h6>
              <div class="d-flex justify-content-between align-items-center" >
                <div>
                  <h6 class="font-weight-bolder">
                    Menciones
                  </h6>
                  <h6 class="font-weight-bolder">
                    ER
                  </h6>
                </div>
                <div>
                  <h4 class="mb-0 text-muted ">
                    {{ item.mentions_count }}
                  </h4>
                  <h4 class="mb-0 text-muted ">{{ item.mention_er }}%</h4>
                </div>
              </div>
              <b-progress
                :value="item.mention_er"
                max="1"
                height="6px"
                :variant="getColor(item.mention_er)"
                ></b-progress>
            </b-card-body>
          </b-card>
        </slide>
        <hooper-navigation slot="hooper-addons"></hooper-navigation>
      </hooper>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  // BRow,
  // BCol,
  BCard,
  BCardBody,
  BImg,
  BCardHeader,
  BCardTitle,
  BProgress,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import "swiper/css/swiper.css";
import Ripple from 'vue-ripple-directive'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
export default {
  components: {
    // BRow,
    // BCol,
    BCard,
    BCardBody,
    BImg,
    BCardHeader,
    BCardTitle,
    BProgress,
    BDropdown,
    BDropdownItem,
    Hooper,
    Slide,
    HooperNavigation
  },
  directives: {
    Ripple,
  },
  props: {
    audienceData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      configSlide: {
        itemsToShow: 4.5,
        infiniteScroll: true,
        progress: true,
        autoPlay: true,
        centerMode: true,
        playSpeed: 2000,
        rtl: false,
        wheelControl: false,
        breakpoints: {
          280: {
            itemsToShow: 1
          },
          375: {
            itemsToShow: 1.5
          },
          414: {
            itemsToShow: 1.5
          },
          768: {
            itemsToShow: 3.5
          },
          1000: {
            itemsToShow: 4.5,
          }
        }
      },
      host : window.location.host
    }
  },
  methods: {
    getColor(level) {
      if (level <= 0.3) return "danger";
      if (level >= 1) return "success";
      return "warning";
    },
    makeLink (brandme, username) {
      if (brandme === true) return `https://${this.host}/perfil/instagram/${username}`
      return `https://instagram.com/${username}`
    },
    update() {
      this.$refs.brandCarousel.updateWidth()
    }
  }
};
</script>
<style lang="scss" scoped>
.hooper {
  height: 100% !important;
}
</style>
